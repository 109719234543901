<script>
import { initJsPsych } from 'jspsych';
import 'jspsych/css/jspsych.css'
import htmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';
import jsPsychSurveyText from '@jspsych/plugin-survey-text';

// import sentences1 from './../../stims/list1.json'
// import sentences2 from './../../stims/list2.json'
// import sentences3 from './../../stims/list3.json'
// import sentences4 from './../../stims/list4.json'
// import sentences5 from './../../stims/list5.json'
// import sentences6 from './../../stims/list6.json'
// import fillers from './../../stims/fillers.json'
// import training from './../../stims/training.json'

import sentences1 from './../../stims/reading-span/two.json'
import sentences2 from './../../stims/reading-span/three.json'
import sentences3 from './../../stims/reading-span/four.json'
import sentences4 from './../../stims/reading-span/five.json'
import sentences5 from './../../stims/reading-span/six.json'
import practice from './../../stims/reading-span/practice.json'
// import training from './../../stims/training_test.json'

var sentences_list = [sentences1, sentences2, sentences3, sentences4, sentences5]
var instructions_string1 = `
<div class="instructions" >
<h2>Bienvenido al test de Amplitud Lectora</h2>
<p>Este test evalúa su capacidad para procesar y retener información de manera simultánea.</p>
<p>Comenzaremos con una breve fase de entrenamiento seguida de la fase experimental.</p>
</br><p>Presione ESPACIO para continuar.</p>
</div>
  `;

var instructions_string2 = `
<div class="instructions" >
<h2>Instrucciones de Entrenamiento:</h2>
<ul>
<li>Verá dos frases en la pantalla, una tras otra, cada una durante 10 segundos.</li>
<li>Decida si cada frase es Verdadera o Falsa.</li>
<li>Ejemplo:</li>
<ul>
<li>El sol gira alrededor de la <b>tierra</b>. (Falsa)</li>
<li>El agua hierve a 100°C a nivel del <b>mar</b>. (Verdadera)</li>
</ul>
<li>Después de ver las frases, se le pedirá que escriba la última palabra de cada frase ―la que aparece en <b>negrita</b>― en el orden en que apareció.</li>
<li>En este ejemplo, debe escribir: "tierra mar".</li>
</ul>
</br><p>Presione ESPACIO para continuar.</p>
</div>
  `;

var instructions_string3 = `
<div class="instructions" >
<h2>Instrucciones Experimentales:</h2>
<ul>
<li>Ahora leerá series de 2 a 6 frases bajo las mismas condiciones.</li>
<li>Decida si cada frase es Verdadera o Falsa.</li>
<li>Ejemplo:</li>
<ul>
<li>Los gatos son capaces de volar. (Falsa)</li>
<li>El chocolate se extrae del cacao. (Verdadera)</li>
</ul>
<li>Después de ver las frases, se le pedirá que escriba la última palabra de cada frase ―la que aparece en <b>negrita</b>― en el orden en que apareció.</li>
<li>En este ejemplo, debe escribir: "volar cacao".</li>
</ul>
</br><p>Presione ESPACIO para continuar.</p>
</div>
  `;

var ending_stim = `
<div class="instructions" >
<h3>Fin del experimento</h3>
<p>Muchas gracias por participar</p><br />
<p>Presione ESPACIO para terminar.</p>
<p>IMPORTANTE: No cierre esta pestaña.</p>
 </div>
`;

// var instructions_string4 = `
// <div class="instructions" >
// <h2>Comienzo del Test</h2>
// <p>¿Está listo para comenzar el test?</p>
// </p>Si necesita hacer alguna pregunta, por favor hágala ahora. Si está listo, presione ESPACIO para comenzar con la fase de entrenamiento.</p>
// </br><p>Presiona ESPACIO para continuar.</p>
// </div>
//   `;


export default {
  name: 'ReadingSpan',
  props: {
    currentid: String,
    currentlist: String
  },
  data() {
    return {
      // sentences: sentences_list[this.currentlist - 1],
      // next_block: 2,
      sentences: [], //sentences_list[sentence_counter],
      timeline: [],
      jsPsych: initJsPsych({ on_finish: this.on_finish })
    }
  },
  methods: {
    on_finish() {
      this.addProperties()
      var experimental_trials = this.jsPsych.data.get().filter({ type: 'experimental' });
      // experimental_trials.displayData('csv')
      // console.log(jsPsych.data.get().filter({ type: 'experimental' })['type']);
      this.postData(experimental_trials)
      this.openNextTask()
    },
    openNextTask() {
      if (this.currentlist == 1) {
        this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
      }
      // this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
    },
    runExperiment() {
      this.jsPsych.run(this.timeline);
    },
    createTrials(trial) {
      let formatted_str = []
      // let curr_block = this.sentences[trial]['block']
      let final = this.sentences[trial]['final']
      // console.log(this.prev_block)
      // console.log(curr_block)
      let str = this.sentences[trial]['sentence'].split(" ");
      for (let s = 0; s < str.length; s++) {
        if (s < str.length - 1) {
          formatted_str.push(str[s])
        }
        else {
          formatted_str.push(['<b>' + str[s] + '</b>'])
        }
      }
      let stim = formatted_str.join(" ");
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: stim,
          choices: " ",
          trial_duration: 10000,
          data: {
            condition: "condition",
            stim: stim,
            expected_answer: "na",
            type: "experimental",
            environment: "reading-span",
            trial: trial
          }
        }
      );
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: '&#8592; Verdadero &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Falso &#8594;',
          choices: ['ArrowLeft', 'ArrowRight'],
          data: {
            condition: 'training question',
            stim: 'question',
            expected_answer: "na",
            type: "experimental",
            environment: "reading-span",
            trial: trial
          },
        }
      );
      if (final == 1) {
        this.next_block++
        this.timeline.push(
          {
            type: jsPsychSurveyText,
            questions: [
              {
                prompt: "Escriba las últimas palabras de cada frase",
                placeholder: "",
                required: false,
                name: ""
              }
            ],
            button_label: "Continuar",
            data: {
              condition: "experimental",
              stim: "",
              expected_answer: "na",
              type: "experimental",
              environment: "reading-span",
              trial: trial
            },
          }
        )
      }
    },
    makeTrials() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string3,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          }
        }
      )
      for (let j = 0; j < sentences_list.length; j++) {
        this.sentences = sentences_list[j]
        this.timeline.push(
          {
            type: htmlKeyboardResponse,
            stimulus: "<p>Ahora, leerá conjuntos de " + (j + 2) + " frases.</p><br /><br />Presione ESPACIO para continuar.",
            choices: " ",
            data: {
              condition: 'instruction',
              stim: " ",
              expected_answer: 'na',
              type: "",
              environment: "reading-span",
              trial: -1
            }
          }
        )
        for (let i = 0; i < this.sentences.length; i++) {
          this.createTrials(i);
        }
        // this.next_block = 2
      }
    },
    makePractice() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string2,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          }
        }
      )
      for (let i = 0; i < practice.length; i++) {
        console.log(practice.length)
        this.createPractice(i);
      }
    },
    createPractice(trial) {
      let formatted_str = []
      // let curr_block = practice[trial]['final']
      let final = practice[trial]['final']
      let str = practice[trial]['sentence'].split(" ");
      for (let s = 0; s < str.length; s++) {
        if (s < str.length - 1) {
          formatted_str.push(str[s])
        }
        else {
          formatted_str.push(['<b>' + str[s] + '</b>'])
        }
      }
      let stim = formatted_str.join(" ");
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: stim,
          choices: " ",
          trial_duration: 10000,
          data: {
            condition: "practice",
            stim: stim,
            expected_answer: "na",
            type: "practice",
            environment: "reading-span",
            trial: trial
          }
        }
      );
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: '&#8592; Verdadero &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Falso &#8594;',
          choices: ['ArrowLeft', 'ArrowRight'],
          data: {
            condition: 'training question',
            stim: 'question',
            expected_answer: "na",
            type: "practice",
            environment: "reading-span",
            trial: trial
          },
        }
      );
      if (final == 1) {
        // this.next_block++
        this.timeline.push(
          {
            type: jsPsychSurveyText,
            questions: [
              {
                prompt: "Escriba las últimas palabras de cada frase",
                placeholder: "",
                required: false,
                name: ""
              }
            ],
            button_label: "Continuar",
            data: {
              condition: 'training question',
              stim: 'response',
              expected_answer: "na",
              type: "training",
              environment: "reading-span",
              trial: trial
            },
          }
        )
      }
    },
    makeGeneralInstructions() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string1,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          }
        }
      )
    },
    makeEndTraining() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p><b>Usted debería haber escrito</b></p><p>Para la primera serie: zorzales moais</p><p>Para la segunda serie: humano temperatura</p><p>Para la tercera serie: verano grandes</p><br /><br />Presione ESPACIO para continuar.",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          },
        }
      )
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p>Este es el fin de la práctica</p><br /><br />Presione ESPACIO para continuar.",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          },
        }
      )
    },
    makeBlockEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: ending_stim,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          },
        }
      )
    },
    makeEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p>Fin del experimento.</p><p>Puede tomarse unos minutos de descanso.</p><p>Cuando se sienta lista/o, presione ESPACIO para continuar.</p>",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "reading-span",
            trial: -1
          },
        }
      )
    },
    async postData(experimental_trials) {
      let url = 'https://www.experimentosonline.cl:3000/markers/'
      // let url = 'http://127.0.0.1:3000/markers/'
      let data = {
        id: experimental_trials.select('ID'),
        rt: experimental_trials.select('rt'),
        stim: experimental_trials.select('stim'),
        response: experimental_trials.select('response'),
        type: experimental_trials.select('condition'),
        expected_answer: experimental_trials.select('expected_answer'),
        environment: experimental_trials.select('environment'),
        list: experimental_trials.select('list'),
      }
      // console.log(experimental_trials.select('trial_index'))
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
        }
      }).then(response => console.log(response.ok))
    },
    addProperties() {
      this.jsPsych.data.addProperties({ ID: this.currentid, list: this.currentlist });
    },
    printID() {
      console.log(this.currentid)
      console.log(this.currentlist)
    }
  },

  mounted() {
    // this.makeWelcome()
    this.makeGeneralInstructions()
    this.makePractice()
    this.makeEndTraining()
    this.makeTrials()
    if (this.currentlist == 2) {
      this.makeBlockEnd()
    } else {
      this.makeEnd()
    }
    this.runExperiment()
    this.printID()
  }
}

</script>