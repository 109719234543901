<template>
  <!-- <div class="header">
    <h1>Experimentos online</h1>
  </div> -->
  <div class="container">
    <div class="button-grid">
      <button>Self Paced Battery</button>
      <button @click="navigateTo('DigitSpanMenu')">Digit Span Alone</button>
      <button>Flanker Task Alone</button>
      <button @click="navigateTo('ReadingSpanMenu')">Reading Span Battery</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationButtons',
  methods: {
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>

<style scoped>
.header {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 60vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
</style>