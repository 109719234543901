<script>
import { initJsPsych } from 'jspsych';
import 'jspsych/css/jspsych.css'
import htmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';

import sentences1 from './../../stims/list1.json'
import sentences2 from './../../stims/list2.json'
import sentences3 from './../../stims/list3.json'
import sentences4 from './../../stims/list4.json'
import sentences5 from './../../stims/list5.json'
import sentences6 from './../../stims/list6.json'
import fillers from './../../stims/fillers.json'
import training from './../../stims/training.json'

// import sentences1 from './../../stims/list1_test.json'
// import sentences2 from './../../stims/list2_test.json'
// import sentences3 from './../../stims/list3_test.json'
// import sentences4 from './../../stims/list4_test.json'
// import sentences5 from './../../stims/list5_test.json'
// import sentences6 from './../../stims/list6_test.json'
// import fillers from './../../stims/fillers_test.json'
// import training from './../../stims/training_test.json'

var sentences_list = [sentences1, sentences2, sentences3, sentences4, sentences5, sentences6]
var instructions_string = `
<div class="instructions" >
    <h2>Self-paced reading task</h2>
    <div>
    <p>In this experiment, you will read sentences one word at a time on the computer screen. Press the spacebar to display each word one at a time and continue pressing to proceed through the sentence at your own time.
    </p>
    <p>After each sentence, a comprehension question will appear. Answer by pressing the left arrow key for "Yes" and the right arrow key for "No." Aim to respond both quickly and accurately.
    </p>
    <p>There are no right or wrong answers, so please respond based on your understanding.
    </p>
    <p>Now, let us practice before starting the actual experiment.
    </p>
    <p>Press SPACE to begin the practice...
    </p>
    </div>
</div>
  `;

var instructions_string2 = `
<div class="instructions" >
    <h2>Self-paced reading task</h2>
    <div>
    <p>That was the practice. 
    </p>
    <p>Press SPACE to continue...
    </p>
    </div>
</div>
  `;

var instructions_string3 = `
<div class="instructions" >
    <h2>Self-paced reading task</h2>
    <div>
    <p>Now you will start the experiment itself. 
    </p>
    <p>If you have questions, please ask the experimenter.
    </p>
    <p>Press SPACE to begin the experiment...
    </p>
    </div>
</div>
  `;

  
export default {
  name: 'SelfPaced',
  props: {
    currentid: String,
    currentlist: Number
  },
  data() {
    return {
      sentences: sentences_list[this.currentlist - 1],
      timeline: [],
      jsPsych: initJsPsych({ on_finish: this.on_finish })
    }
  },
  methods: {
    on_finish() {
      //this.jsPsych.data.displayData('json')
      this.addProperties()
      var experimental_trials = this.jsPsych.data.get().filter({ type: 'experimental' });
      // console.log(jsPsych.data.get().filter({ type: 'experimental' })['type']);
      this.postData(experimental_trials)
      this.openNextTask()
    },
    openNextTask() {
      this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
    },
    runExperiment() {
      this.jsPsych.run(this.timeline);
    },
    createTraining(trial) {
      let str = training[trial]['sentence'].split(" ");
      let mask = [];
      for (let word of str) {
        mask.push('_'.repeat(word.length));
      }
      for (let i = 0; i < mask.length; i++) {
        let mask2 = mask.slice();
        mask2[i] = str[i]
        this.timeline.push({
          type: htmlKeyboardResponse,
          stimulus: mask2.join(" "),
          choices: " ",
          data: {
            condition: "training",
            stim: mask2[i],
            expected_answer: "na",
            type: "experimental",
            environment: "self-paced",
            trial: trial
          }
        }
        );
      }
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: training[trial]['question'] + '<br /> &#8592; Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No &#8594;',
          choices: ['ArrowLeft', 'ArrowRight'],
          data: {
            condition: 'training question',
            stim: training[trial]['question'],
            expected_answer: "na",
            type: "experimental",
            environment: "self-paced",
            trial: trial
          },
        }
      );
    },
    createTrials(trial) {
      let str = this.sentences[trial]['sentence'].split(" ");
      let mask = [];
      for (let word of str) {
        mask.push('_'.repeat(word.length));
      }
      for (let i = 0; i < mask.length; i++) {
        let mask2 = mask.slice();
        mask2[i] = str[i]
        this.timeline.push(
          {
            type: htmlKeyboardResponse,
            stimulus: mask2.join(" "),
            choices: " ",
            data: {
              condition: this.sentences[trial]['condition'],
              stim: mask2[i],
              expected_answer: "na",
              type: "experimental",
              environment: "self-paced",
              trial: trial
            }
          }
        );
      }
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: this.sentences[trial]['question'] + '<br /> &#8592; Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No &#8594;',
          choices: ['ArrowLeft', 'ArrowRight'],
          data: {
            condition: 'sintax question',
            stim: this.sentences[trial]['question'],
            expected_answer: this.sentences[trial]['expected_answer'],
            type: "experimental",
            environment: "self-paced",
            trial: trial
          }
        }
      );
      let fill = fillers[trial]['sentence'].split(" ");
      let mask_fill = [];
      for (let word of fill) {
        mask_fill.push('_'.repeat(word.length));
      }
      for (let i = 0; i < mask_fill.length; i++) {
        let mask2_fill = mask_fill.slice();
        mask2_fill[i] = fill[i]
        this.timeline.push({
          type: htmlKeyboardResponse,
          stimulus: mask2_fill.join(" "),
          choices: " ",
          data: {
            condition: fillers[trial]['type'],
            stim: mask2_fill[i],
            expected_answer: 'na',
            type: "experimental",
            environment: "self-paced",
            trial: trial
          }
        }
        );
      }
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: fillers[trial]['question'] + '<br /> &#8592; Yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No &#8594;',
          choices: ['ArrowLeft', 'ArrowRight'],
          data: {
            condition: 'filler question',
            stim: fillers[trial]['question'],
            expected_answer: 'na',
            type: "experimental",
            environment: "self-paced",
            trial: trial
          },
        }
      );
    },
    makeTraining() {
      for (let i = 0; i < training.length; i++) {
        this.createTraining(i);
      }
    },
    makeTrials() {
      for (let i = 0; i < this.sentences.length; i++) {
        this.createTrials(i);
      }
    },
    makeInstructions() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "self-paced",
            trial: -1
          }
        }
      )
    },
    makeEndTraining() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string2,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "self-paced",
            trial: -1
          },
        }
      )
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string3,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "self-paced",
            trial: -1
          },
        }
      )
    },
    makeEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p>Fin del experimento.</p><p>Puedes tomarte unos minutos de descanso.</p><p>Cuando te sientas lista/o, presiona ESPACIO para continuar...</p>",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "self-paced",
            trial: -1
          },
        }
      )
    },
    async postData(experimental_trials) {
      let url = 'https://www.experimentosonline.cl:3000/markers/'
      // let url = 'http://127.0.0.1:3000/markers/'
      let data = {
        id: experimental_trials.select('ID'),
        rt: experimental_trials.select('rt'),
        stim: experimental_trials.select('stim'),
        response: experimental_trials.select('response'),
        type: experimental_trials.select('condition'),
        expected_answer: experimental_trials.select('expected_answer'),
        environment: experimental_trials.select('environment'),
        list: experimental_trials.select('list'),
      }
      // console.log(experimental_trials.select('trial_index'))
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
        }
      }).then(response => console.log(response.ok))
    },
    addProperties() {
      this.jsPsych.data.addProperties({ ID: this.currentid, list: this.currentlist });
    },
    printID() {
      console.log(this.currentid)
      console.log(this.currentlist)
    }
  },

  mounted() {
    // this.makeWelcome()
    this.makeInstructions()
    this.makeTraining()
    this.makeEndTraining()
    this.makeTrials()
    this.makeEnd()
    this.runExperiment()
    this.printID()
  }
}

</script>