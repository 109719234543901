<script>
import { initJsPsych } from 'jspsych';
import 'jspsych/css/jspsych.css'
import htmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';

var trial_type = ['left_congruent', 'left_congruent', 'left_congruent', 'left_incongruent',
  'right_congruent', 'right_congruent', 'right_congruent', 'right_incongruent']
var trial_stim = ['< < < < < < <', '< < < < < < <', '< < < < < < <', '< < < > < < <',
  '> > > > > > >', '> > > > > > >', '> > > > > > >', '> > > < > > >']
const trials_per_block = 10
const n_blocks = 5

var instructions_string = `
<div class="instructions" >
    <h2>Test de Flanker</h2>
    <div>
      <p>Este test evalúa su capacidad de atención y control inhibitorio.</p>
      <h3>1. Procedimiento:</h3>
      <ul>
        <li>Verá una fila de flechas en la pantalla.</li>
        <li>Debe indicar la dirección de la flecha central, ignorando las flechas de los lados.</li>
        <li>Use las teclas de flecha para responder: izquierda (←) o derecha (→).</li>
      </ul>
      <h3>2. Ejemplo:</h3>
      <ul>
        <li>Si ve "← ← → ← ←", la flecha central es "→", por lo que debe presionar la tecla de flecha derecha (→).</li>
      </ul>
      <p>Concéntrese y responda lo más rápido y preciso posible. Vamos a empezar con una secuencia de prueba.</p>
      <p>Presione ESPACIO para continuar.</p>
    </div>
  </div>
  `;


export default {
  name: 'FlankerTask',
  props: {
    currentid: String,
    currentlist: Number
  },
  data() {
    return {
      timeline: [],
      jsPsych: initJsPsych({ on_finish: this.on_finish }),
      random_stims_list: Array,
      random_stims_type: Array
    }
  },
  methods: {
    randomize_trials(n_repetitions = 1) {
      let stim_type = Array(n_repetitions).fill(trial_type).flat()
      let stim_list = Array(n_repetitions).fill(trial_stim).flat()
      let random_trials = [];
      for (let i = 0; i < stim_list.length; i++) {
        random_trials.push(i);
      }
      let random_stims_list = []
      let random_stims_type = []
      for (let i = 0; i < stim_list.length; i++) {
        let randomIndex = Math.floor(Math.random() * random_trials.length);
        let random = random_trials.splice(randomIndex, 1)[0];
        random_stims_list.push(stim_list[random])
        random_stims_type.push(stim_type[random])
      }
      return [random_stims_list, random_stims_type]
    },
    on_finish() {
      // console.log(this.jsPsych.data.displayData('json'))
      this.addProperties()
      var experimental_trials = this.jsPsych.data.get().filter({ type: 'experimental' });
      this.postData(experimental_trials)
      this.openNextTask()
    },
    openNextTask() {
      if (this.currentlist == 1) {
          this.$router.push({ name: 'DigitSpanFvH', params: { currentid: this.currentid, currentlist: this.currentlist } })
        }
        else {
          this.$router.push({ name: 'ReadingSpan', params: { currentid: this.currentid, currentlist: this.currentlist } })
        }
      // this.$router.push({ name: 'DigitSpan', params: { currentid: this.currentid, currentlist: this.currentlist } })
    },
    runExperiment() {
      this.jsPsych.run(this.timeline);
    },
    createTraining(trial) {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: '<p style="font-size:45px;color:red;">' + this.random_stims_list[trial] + '</p>',
        choices: ['ArrowLeft', 'ArrowRight'],
        data: {
          condition: 'training',
          stim: this.random_stims_list[trial],
          expected_answer: "na",
          type: "experimental",
          environment: "flanker",
          trial: trial
        }
      })
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: '',
        choices: '',
        trial_duration: Math.random() * (1000 - 300) + 300,
        data: {
          condition: 'iei',
          stim: '',
          expected_answer: "na",
          type: "experimental",
          environment: "flanker",
          trial: trial,
        }
      }
      );
    },
    makeTraining() {
      [this.random_stims_list, this.random_stims_type] = this.randomize_trials()
      for (let i = 0; i < this.random_stims_list.length; i++) {
        this.createTraining(i);
      }
    },
    createTrials(trial) {
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: '<p style="font-size:45px;color:red;">' + this.random_stims_list[trial] + '</p>',
        choices: ['ArrowLeft', 'ArrowRight'],
        data: {
          condition: this.random_stims_type[trial],
          stim: this.random_stims_list[trial],
          expected_answer: "na",
          type: "experimental",
          environment: "flanker",
          trial: trial
        }
      })
      this.timeline.push({
        type: htmlKeyboardResponse,
        stimulus: '',
        choices: '',
        trial_duration: Math.random() * (1000 - 300) + 300,
        data: {
          condition: 'iei',
          stim: '',
          expected_answer: "na",
          type: "experimental",
          environment: "flanker",
          trial: trial
        }
      }
      );
    },
    makeTrials() {
      [this.random_stims_list, this.random_stims_type] = this.randomize_trials(trials_per_block)
      for (let b = 0; b < n_blocks; b++) {
        for (let i = 0; i < this.random_stims_list.length; i++) {
          this.createTrials(i);
        }
        this.makeEndBlock(b)
      }
    },
    makeEndBlock(block) {
      block++
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "Fin del bloque." + block + ".<br /><br />Presione ESPACIO para continuar...",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: "",
            expected_answer: 'na',
            type: "",
            environment: "flanker",
            trial: -1
          }
        }
      )
    },
    // makeWelcome() {
    //   this.timeline.push(
    //     {
    //       type: htmlKeyboardResponse,
    //       stimulus: "Welcome to the experiment.<br /><br />Press SPACE to continue...",
    //       choices: " ",
    //       data: {
    //         condition: 'instruction',
    //         stim: "",
    //         expected_answer: 'na',
    //         type: "",
    //         environment: "flanker",
    //         trial: -1
    //       }
    //     }
    //   )
    // },
    makeInstructions() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "flanker",
            trial: -1
          }
        }
      )
    },
    makeEndTraining() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "Fin de la práctica.<br /><br />Presione ESPACIO para iniciar el experimento real...",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "flanker",
            trial: -1
          },
        }
      )
    },
    printID() {
      console.log(this.currentid)
      console.log(this.currentlist)
    },
    async postData(experimental_trials) {
      let url = 'https://www.experimentosonline.cl:3000/markers/'
      // let url = 'http://127.0.0.1:3000/markers/'
      let data = {
        id: experimental_trials.select('ID'),
        rt: experimental_trials.select('rt'),
        stim: experimental_trials.select('stim'),
        response: experimental_trials.select('response'),
        type: experimental_trials.select('condition'),
        expected_answer: experimental_trials.select('expected_answer'),
        environment: experimental_trials.select('environment'),
        list: experimental_trials.select('list'),
      }
      // console.log(experimental_trials.select('trial_index'))
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
        }
      }).then(response => console.log(response.ok))
    },
    addProperties() {
      this.jsPsych.data.addProperties({ ID: this.currentid, list: this.currentlist });
    },
    makeEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p>Fin del experimento.</p><p>Puede tomarse unos minutos de descanso.</p><p>Cuando se sienta lista/o, presione ESPACIO para continuar.</p>",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "flanker-task",
            trial: -1
          },
        }
      )
    },
  },
  mounted() {
    this.printID()
    this.makeInstructions()
    this.makeTraining()
    this.makeEndTraining()
    this.makeTrials()
    this.makeEnd()
    this.runExperiment()
  }
}

</script>
