<script>
import { initJsPsych } from 'jspsych';
import 'jspsych/css/jspsych.css'
// import htmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';
// import jsPsychSurveyText from '@jspsych/plugin-survey-text';
import jsPsychPreload from '@jspsych/plugin-preload';
import jsPsychAudioKeyboardResponse from '@jspsych/plugin-audio-keyboard-response';
import jsPsychHtmlAudioResponse from '@jspsych/plugin-html-audio-response';
import jsPsychInitializeMicrophone from '@jspsych/plugin-initialize-microphone';
import htmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response';

// var tone = require('./../../resources/tone/tone.ogg')
var forw_practice = [require('./../../resources/practice/forward/directo1.ogg'), require('./../../resources/practice/forward/directo2.ogg')]
var back_practice = [require('./../../resources/practice/backward/inverso1.ogg'), require('./../../resources/practice/backward/inverso2.ogg')]
var forw_stims = [require('./../../resources/forward/directo1.ogg'), require('./../../resources/forward/directo2.ogg'),
    require('./../../resources/forward/directo3.ogg'), require('./../../resources/forward/directo4.ogg'),
    require('./../../resources/forward/directo5.ogg'), require('./../../resources/forward/directo6.ogg'),
    require('./../../resources/forward/directo7.ogg'), require('./../../resources/forward/directo8.ogg'),
    require('./../../resources/forward/directo9.ogg'), require('./../../resources/forward/directo10.ogg'),
    require('./../../resources/forward/directo11.ogg'), require('./../../resources/forward/directo12.ogg'),
    require('./../../resources/forward/directo13.ogg'), require('./../../resources/forward/directo14.ogg'),
    require('./../../resources/forward/directo15.ogg'), require('./../../resources/forward/directo16.ogg')
]
var back_stims = [require('./../../resources/backward/inverso1.ogg'), require('./../../resources/backward/inverso2.ogg'),
    require('./../../resources/backward/inverso3.ogg'), require('./../../resources/backward/inverso4.ogg'),
    require('./../../resources/backward/inverso5.ogg'), require('./../../resources/backward/inverso6.ogg'),
    require('./../../resources/backward/inverso7.ogg'), require('./../../resources/backward/inverso8.ogg'),
    require('./../../resources/backward/inverso9.ogg'), require('./../../resources/backward/inverso10.ogg'),
    require('./../../resources/backward/inverso11.ogg'), require('./../../resources/backward/inverso12.ogg'),
    require('./../../resources/backward/inverso13.ogg'), require('./../../resources/backward/inverso14.ogg'),
    require('./../../resources/backward/inverso15.ogg'), require('./../../resources/backward/inverso16.ogg')
]

var instructions_string = `
<div class="instructions" >
<h2>Instruciones</h2>
<p>Esta prueba consiste en dos tareas. La primera se llama “Retención de dígitos en orden directo”. La segunda se denomina “Retención de dígitos en orden inverso”.</p>
<p>En la Tarea 1: “Retención de dígitos en orden directo”, escuchará una secuencia de números que luego usted deberá repetir en el mismo orden.</p> 
<p>En la Tarea 2: “Retención de dígitos en orden inverso”, escuchará una secuencia de números que luego usted deberá repetir en orden inverso. </p><br />
<p>Presione ESPACIO para continuar.</p>
</div>
`;

var instructions_string1 = `
<div class="instructions" >
<h3>Tarea 1: Retención de dígitos en orden directo</h3>
<p>Retención de dígitos en orden directo: ítemes de práctica</p>
<p>Usted escuchará una serie de números. Deberá repetirlos en el mismo orden en que los escuchó. Si escucha "5 - 2", debería  repetir "5 - 2".<p/>
<p>Vamos a escuchar una serie de números. Recuerde repetir los números en el mismo orden en que los escuchó: Si usted escucha "7 - 1", debería repetir: "7 - 1"</p>
<p>Usted tendrá un tiempo máximo de 30 segundos para repetir cada una de las series de números. En caso de que no recuerde la serie o que diga la serie en menos
  de 30 segundos, puede continuar presionando la barra espaciadora.<p/><br />
<p>Presione ESPACIO para continuar.</p>
</div>
`;

var instructions_string2 = `
<div class="instructions" >
<h3>Tarea 1: Retención de dígitos en orden directo</h3>
<p>Retención de dígitos en orden directo: ítemes de práctica</p>
<p>Ahora, vamos a comenzar con la tarea 1. Recuerde repetir la secuencia en el mismo orden después del tono que escuchará a continuación.</p><br />
<p>Presione ESPACIO para continuar.</p>
 </div>
`;

var instructions_string3 = `
<div class="instructions" >
<h3>Tarea 2: Retención de dígitos en orden inverso</h3>
<p>Retención de dígitos en orden inverso: ítemes de práctica</p>
<p>Ahora, escuchará otra serie de números, pero esta vez la tendrá que repetir en orden inverso. Luego de escuchar: "7 - 1", debería usted repetir "1 - 7".<p/>
<p>Vamos a ver otro ejemplo. Recuerde decir las series en orden inverso: "3 - 4". En este caso la respuesta correcta sería: "4 -3". </p><br />
<p>Presione ESPACIO para continuar.</p>
</div>
`;

var instructions_string4 = `
<div class="instructions" >
<h3>Tarea 2: Retención de dígitos en orden inverso</h3>
<p>Retención de dígitos en orden inverso: ítemes de práctica</p>
<p>Ahora, vamos a comenzar con la tarea 2. Recuerde repetir la secuencia en orden inverso después del tono que escuchará a continuación.</p><br />
<p>Presione ESPACIO para continuar.</p>
 </div>
`;

var ending_stim = `
<div class="instructions" >
<h3>Fin del experimento</h3>
<p>Muchas gracias por participar</p><br />
<p>Presione ESPACIO para terminar.</p>
<p>IMPORTANTE: No cierre esta pestaña.</p>
 </div>
`;

export default {
  name: 'DigitSpanFvH',
  props: {
    currentid: String,
    currentlist: Number
  },
  data() {
    return {
      // stim: Number,
      trial: Number,
      timeline: [],
      trial_stim: Number,
      // n_trials: stim_list.length,
      jsPsych: initJsPsych({ on_finish: this.on_finish })
    }
  },
  methods: {
    preload() {
      this.timeline.push({
        type: jsPsychPreload,
        auto_preload: true
      })
    },
    initMic() {
      this.timeline.push({
        type: jsPsychInitializeMicrophone,
        button_label: 'Utilizar este micrófono'
      })
    },
    runExperiment() {
      this.jsPsych.run(this.timeline);
    },
    on_finish() {
      // console.log(this.jsPsych.data.displayData('json'))
      this.addProperties()
      var experimental_trials = this.jsPsych.data.get().filter({ type: 'experimental' });
      this.postData(experimental_trials)
      this.openNextTask()
    },
    openNextTask() {
      if (this.currentlist == 2) {
        this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
      }
      // this.$router.push({ name: 'FlankerTask', params: { currentid: this.currentid, currentlist: this.currentlist } })
    },
    makeForwardInstructions() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          }
        }
      )
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string1,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          }
        }
      )
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string2,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          }
        }
      )
      // this.timeline.push({
      //   type: jsPsychAudioKeyboardResponse,
      //   stimulus: tone,
      //   trial_ends_after_audio: false,
      //   choices: " ",
      //   prompt: "Acaba de escuchar el tono que le indicará que debe repetir la secuencia.<br />Presione ESPACIO para continuar.",
      //   data: {
      //     condition: 'instruction',
      //     stim: " ",
      //     expected_answer: "na",
      //     type: "",
      //     environment: "span",
      //     trial: -1
      //   },
      // })
    },
    makeBackwardInstructions() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string3,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          }
        }
      )
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: instructions_string4,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          }
        }
      )
      // this.timeline.push({
      //   type: jsPsychAudioKeyboardResponse,
      //   stimulus: tone,
      //   trial_ends_after_audio: false,
      //   choices: " ",
      //   prompt: "Acaba de escuchar el tono que le indicará que debe repetir la secuencia.<br />Presione ESPACIO para continuar.",
      //   data: {
      //     condition: 'instruction',
      //     stim: " ",
      //     expected_answer: "na",
      //     type: "",
      //     environment: "span",
      //     trial: -1
      //   },
      // })
    },
    makeEndPractice() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "Éste es el fin de la práctica.<br /><br />Presione ESPACIO para pasar al experimento real.",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          },
        }
      )
    },
    createStims(stim, condition, type = "experimental") {
      console.log(stim)
      this.timeline.push({
        type: jsPsychAudioKeyboardResponse,
        stimulus: stim,
        trial_ends_after_audio: true,
        choices: '',
        prompt: '',
        data: {
          condition: condition,
          stim: stim,
          expected_answer: "na",
          type: "",
          environment: "span",
          trial: 1
        },
      })
      // this.timeline.push({
      //   type: jsPsychAudioKeyboardResponse,
      //   stimulus: tone,
      //   trial_ends_after_audio: true,
      //   choices: '',
      //   prompt: '',
      //   data: {
      //     condition: condition,
      //     stim: stim,
      //     expected_answer: "na",
      //     type: "",
      //     environment: "span",
      //     trial: 1
      //   },
      // })
      this.timeline.push({
        type: jsPsychHtmlAudioResponse,
        stimulus: 'Repita los números.',
        recording_duration: 30000,
        done_button_label: 'Continuar',
        data: {
          condition: condition,
          stim: stim,
          expected_answer: "na",
          type: type,
          environment: "span",
          trial: 1
        },
      })
    },
    makeForwardExperiment() {
      this.makeForwardInstructions()
      this.makeForwardPractice()
      this.makeEndPractice()
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "Repita los números en el mismo orden en que los escucha.<br /><br />Presione ESPACIO para continuar.",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          },
        }
      )
      for (let i = 0; i < forw_stims.length; i++) {
        this.createStims(forw_stims[i], 'forward');
      }
    },
    makeBackwardExperiment() {
      this.makeBackwardInstructions()
      this.makeBackwardPractice()
      this.makeEndPractice()
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "Repita los números en orden inverso.<br /><br />Presione ESPACIO para continuar.",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          },
        }
      )
      for (let i = 0; i < back_stims.length; i++) {
        this.createStims(back_stims[i], 'backward');
      }
    },
    makeForwardPractice() {
      for (let i = 0; i < 2; i++) {
        this.createStims(forw_practice[i], 'forward', "");
      }
    },
    makeBackwardPractice() {
      for (let i = 0; i < 2; i++) {
        this.createStims(back_practice[i], 'backward', "");
      }
    },
    addProperties() {
      this.jsPsych.data.addProperties({ ID: this.currentid, list: this.currentlist });
    },
    makeBlockEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: ending_stim,
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "span",
            trial: -1
          },
        }
      )
    },
    makeEnd() {
      this.timeline.push(
        {
          type: htmlKeyboardResponse,
          stimulus: "<p>Fin del experimento.</p><p>Puede tomarse unos minutos de descanso.</p><p>Cuando se sienta lista/o, presione ESPACIO para continuar.</p>",
          choices: " ",
          data: {
            condition: 'instruction',
            stim: " ",
            expected_answer: 'na',
            type: "",
            environment: "flanker-task",
            trial: -1
          },
        }
      )
    },
    async postData(experimental_trials) {
      let url = 'https://www.experimentosonline.cl:3000/markers/'
      // let url = 'http://127.0.0.1:3000/markers/'
      let data = {
        id: experimental_trials.select('ID'),
        rt: experimental_trials.select('rt'),
        stim: experimental_trials.select('stim'),
        response: experimental_trials.select('response'),
        type: experimental_trials.select('condition'),
        expected_answer: experimental_trials.select('expected_answer'),
        environment: experimental_trials.select('environment'),
        list: experimental_trials.select('list'),
      }
      // console.log(experimental_trials.select('trial_index'))
      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*"
        }
      }).then(response => console.log(response.ok))
    },
  },
  mounted() {
    this.preload()
    this.initMic()
    // this.makeForwardInstructions()
    // this.makeForwardPractice()
    // this.makeForwardEndPractice()
    this.makeForwardExperiment()
    // this.makeBackwardInstructions()
    // this.makeBackwardPractice()
    // this.makeBackwardEndPractice()
    this.makeBackwardExperiment()
    if (this.currentlist == 1) {
      this.makeBlockEnd()
    } else {
      this.makeEnd()
    }
    this.runExperiment()
  }
}
</script>