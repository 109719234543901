<template>
  <div class="instructions" v-if="showInstructions">
    <h2>Bienvenido al experimento</h2>
    <div>
      <p>Espere por las instrucciones del profesor...</p>
      <input v-model="accept" type="checkbox" id="vehicle1" name="vehicle1" @click="buttonPress">
      <label for="vehicle1">El profesor me ha autorizado a continuar con el experimento</label><br>
      <div class="validateButton">
        <button type="submit" v-if="showButton" @click="showSubjMenu">Continuar</button>
      </div>
    </div>

  </div>

  <div class="hello" v-if="showMenu">
    <div>
      <form>
        <h2>Por favor, escriba su nombre y apellido.</h2>
        <label>Nombre<br />
          <input type="text" required v-model="user.name"> </label> <br /> <br />
        <label>Apellido<br />
          <input type="text" required v-model="user.surname"> </label> <br /> <br />
        <h2>Espere que el profesor le indique su código.</h2>
        <label>Código<br />
          <input type="text" required v-model="user.list"> </label> <br /> <br />
        <input v-model="accept2" type="checkbox" id="vehicle2" name="vehicle2" @click="buttonPress2">
        <label for="vehicle2">Declaro que los datos son correctos</label><br>

      </form>
      <div class="validateButton">
        <button type="submit" v-if="showButton2" @click="validateSubj">Iniciar
          experimento</button>
      </div>
    </div>
  </div>
</template>

<script>
// var datetime = new Date().today() + " @ " + new Date().timeNow();
const currentdate = new Date();
const datetime = currentdate.getFullYear() + '_' + currentdate.getMonth() + 1 + '_' + currentdate.getDate()
  + '_' + currentdate.getHours() + '_' + currentdate.getMinutes() + '_' + currentdate.getSeconds()


export default {
  name: 'DigitSpanMenu',
  props: {
    currentid: String,
    currentlist: Number
  },
  data() {
    return {
      datetime: datetime,
      user: { name: '', surname: '', list: '' },
      showMenu: false,
      showInstructions: true,
      showButton: false,
      showButton2: false,
      accept: false,
      accept2: false
    }
  },
  methods: {
    validateSubj() {
      console.log(this.user.name.length)
      if ((this.user.name.length > 0) && (this.user.surname.length > 0) && (this.user.list.length > 0)) {
        // if ((this.user.list >= 1) && (this.user.list <= 6)) {
        this.$router.push({ name: 'DigitSpanMV', params: { currentid: this.user.name + this.user.surname + '_' + this.datetime, currentlist: this.user.list } })
        // }
      }
    },
    showSubjMenu() {
      this.showMenu = true,
        this.showInstructions = false
    },
    buttonPress() {
      console.log(this.accept)
      if (!this.accept) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    buttonPress2() {
      if (!this.accept2) {
        this.showButton2 = true
      } else {
        this.showButton2 = false
      }
    }
  }
}
</script>

<style>
html * {
  font-size: 15.75pt !important;
  color: #000 !important;
  font-family: Courier !important;
}

.hello {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
}

.validateButton {
  position: absolute;
  /*  margin-top: 150px; */
  /*display: block;
  margin: 0 auto; */
  margin-top: 50px;
  margin-left: 100px;
}

button {
  width: 300px;
  height: 40px;
  border: 2px solid skyblue;
  background: skyblue;
  color: #fff;
  cursor: pointer;
}
</style>
