import { createRouter, createWebHistory } from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'
import SelfPaced from './components/SelfPaced.vue'
import FlankerTask from './components/FlankerTask.vue'
import DigitSpanMV from './components/DigitSpanMV.vue'
import DigitSpanFvH from './components/DigitSpanFvH.vue'
import NavigationButtons from './components/MainMenu.vue'
import DigitSpanMenu from './components/DigitSpanMenu.vue'
import ReadingSpanMenu from './components/ReadingSpanMenu.vue'
import ReadingSpan from './components/ReadingSpan.vue'


const routes = [
    {
        name: 'NavigationButtons',
        component: NavigationButtons,
        path: '/'
    },
    {
        name: 'HelloWorld',
        component: HelloWorld,
        path: '/hello-world'
    },
    {
        name: 'SelfPaced',
        component: SelfPaced,
        path: '/self-paced/:currentid/list/:currentlist',
        props: true
    },
    {
        name: 'FlankerTask',
        component: FlankerTask,
        path: '/flanker-task/:currentid/list/:currentlist',
        props: true
    },
    {
        name: 'DigitSpanMV',
        component: DigitSpanMV,
        path: '/digit-span/:currentid/list/:currentlist',
        props: true
    },
    {
        name: 'DigitSpanFvH',
        component: DigitSpanFvH,
        path: '/digit-span/:currentid/list/:currentlist',
        props: true
    },
    {
        name: 'DigitSpanMenu',
        component: DigitSpanMenu,
        path: '/digit-span-menu/',
        // props: true
    },
    {
        name: 'ReadingSpanMenu',
        component: ReadingSpanMenu,
        path: '/reading-span-menu/',
        // props: true
    },
    {
        name: 'ReadingSpan',
        component: ReadingSpan,
        path: '/reading-span/:currentid/list/:currentlist',
        props: true
    }
]

const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
)

export default router
